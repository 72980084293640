import React from "react"
import config from "../config"

const Redirect = () => {
  React.useEffect(() => {
    // eslint-disable-next-line no-undef
    window.location.replace(`${config.HANDBOOK_HOST}/?to=dashboard`)
  }, [])
  return null
}

export default Redirect
